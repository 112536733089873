import React from "react"

const LogoSVG = ({ color = "currentColor", ...props }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 663.9 238.6"
      width="180"
      style={{ enableBackground: "new 0 0 663.9 238.6" }}
      {...props}
    >
      <g transform="translate(-46.606 -337.458)">
        <g>
          <g transform="translate(46.606 337.458)">
            <path
              fill={color}
              d="M663.9,238.6H0V0h663.9V238.6z M18.4,220.3h627.2V18.4H18.4V220.3z"
            />
          </g>
          <g transform="translate(126.153 393.356)">
            <g>
              <g transform="translate(0 12.597)">
                <path
                  fill={color}
                  d="M101,41.8l-24.8,71.5H58.5L33.7,41.8v71.5H0V0h49.1l19.5,57.7L88.1,0h46.5v113.2H101V41.8z"
                />
              </g>
              <g transform="translate(152.029 9.681)">
                <path
                  fill={color}
                  d="M62,0c35,0,62,23.8,62,58.6s-27,58.6-62,58.6S0,93.4,0,58.6S27,0,62,0z M62,30.2
						c-16.5,0-27.2,12.6-27.2,28.4S45.5,86.9,62,86.9s27.2-12.6,27.2-28.4S78.5,30.2,62,30.2z"
                />
              </g>
              <g transform="translate(289.021)">
                <path
                  fill={color}
                  d="M0,17.1C0,7.7,7.7,0,17.2,0c9.5,0,17.1,7.7,17.1,17.2c0,9.5-7.7,17.1-17.1,17.1
						C7.7,34.3,0,26.7,0,17.3C0,17.2,0,17.2,0,17.1z M1.6,43.2h30.6v82.6H1.6V43.2z"
                />
              </g>
              <g transform="translate(342.114 0.358)">
                <path
                  fill={color}
                  d="M34.3,107.3c0,9.5-7.7,17.2-17.2,17.2S0,116.8,0,107.3s7.7-17.2,17.2-17.2c0,0,0,0,0,0
						c9.4,0,17.1,7.6,17.2,17C34.3,107.2,34.3,107.3,34.3,107.3z M31.1,82.6H0.5V0h30.6V82.6z"
                />
              </g>
              <g transform="translate(397.739 12.597)">
                <path
                  fill={color}
                  d="M33.7,52.4v60.8H0V0h34.7l38.7,57V0h33.7v113.2H74.7L33.7,52.4z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoSVG
