import React from "react"
import { Box, Heading, Image, PseudoBox, Text } from "@chakra-ui/core"

import { Col, Container, Row } from "react-grid-system"
import image from "../images/homepage/quote-image.png"

const ImageQuote = ({ text, alt = "" }) => {
  return (
    <Box my={{ base: "20", lg: "32" }}>
      <Box as={Container}>
        <Box as={Row}>
          <Heading>{text}</Heading>

          <Col md={9} push={{ md: 3 }}>
            <Image src={image} alt={alt} />
            <PseudoBox
              pos="absolute"
              top="-10%"
              right="-30px"
              size="140px"
              border="10px solid"
              borderColor="secondary"
              zIndex="hide"
              // animate={{
              //   scale: [1, 1.5, 1.5, 1, 1],
              //   rotate: [0, 0, 180, 180, 0],
              // }}
              // transition={{
              //   duration: 2,
              //   ease: "easeInOut",
              //   times: [0, 0.2, 0.5, 0.8, 1],
              //   loop: Infinity,
              //   repeatDelay: 2,
              //   delay: 1,
              // }}
            />
          </Col>
          <Box
            pos={{ lg: "absolute" }}
            top="50%"
            zIndex="1"
            w="100%"
            transform={{ base: "unset", lg: "translateY(-50%)" }}
          >
            <Col md={9}>
              <Heading
                fontSize={{ base: "3xl", lg: "6xl", xl: "5xl" }}
                bg="primary"
                color="white"
                display="inline"
                lineHeight="1.4"
              >
                <Text
                  as="span"
                  boxShadow={`-10px 0px 0 7px #FAC317, 10px 0px 0 7px #FAC317, 0 0 0 7px #FAC317`}
                  style={{
                    boxDecorationBreak: "clone",
                  }}
                >
                  MOIIN und Willkommen. Wo wir sind und wo wir arbeiten.
                </Text>
              </Heading>
            </Col>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ImageQuote
