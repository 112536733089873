import * as React from "react"
import { motion } from "framer-motion"
import { MenuItem } from "./MenuItem"

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

export const Navigation = ({ ...props }) => (
  <motion.div
    variants={variants}
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {menuItems.map((item, i) => (
      <MenuItem to={item.link} title={item.name} key={i} {...props} />
    ))}
  </motion.div>
)

const menuItems = [
  {
    name: "Startseite",
    link: "/",
  },
  {
    name: "Unser Angebot",
    link: "/angebot",
  },
  {
    name: "Aktuelles",
    link: "/blog",
  },
  {
    name: "Netzwerkpartner",
    link: "/partner",
  },
]
