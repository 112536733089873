import React from "react"
import { Box } from "@chakra-ui/core"
import { Container } from "react-grid-system"
import Block from "@sanity/block-content-to-react"

import {
  BlockRenderer,
  ListRenderer,
  ListItemRenderer,
} from "../../../utils/htmlSerializer"

const BlockContent = ({ input }) => {
  return (
    <Box my={{ base: "20", lg: "32" }}>
      <Container>
        <Block
          blocks={input._rawBlock}
          serializers={{
            types: { block: BlockRenderer },
            list: ListRenderer,
            listItem: ListItemRenderer,
          }}
        />
      </Container>
    </Box>
  )
}

export default BlockContent
