import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import { useCycle, motion } from "framer-motion"
import { Box, Flex, PseudoBox } from "@chakra-ui/core"

import { Container } from "react-grid-system"
import { MenuToggle } from "./navigation/MenuToggle"
import ButtonModal from "./navigation/Modal"
import { LogoSVG } from "./"

export const NavigationItem = ({ to, children }) => {
  return (
    <PseudoBox
      fontWeight={{ base: "bold", lg: "normal" }}
      _hover={{ color: "secondary" }}
      ml={{ lg: "10" }}
      mb={{ base: "8", lg: "0" }}
      fontSize="2xl"
      display={{ base: "block", lg: "inline-block" }}
    >
      <Link to={to} activeStyle={{ color: "#61C0CB" }}>
        {children}
      </Link>
    </PseudoBox>
  )
}

const Navigation = ({ logoAnimation }) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const [onTop, setOnTop] = useState(true)

  const MotionPseudoBox = motion.custom(PseudoBox)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (currentScrollY <= 60) {
        setOnTop(true)
      }
      if (currentScrollY >= 61) {
        setOnTop(false)
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [onTop])

  return (
    <Box as="nav" bg="white" zIndex="sticky" pos="fixed" w="100%" top="0">
      <Box as={Container} zIndex="modal">
        <Flex align="center" wrap="wrap" justify="space-between" minH="96px">
          <Box>
            <Box display={{ base: "none", xl: "block" }}>
              <motion.div
                initial={false}
                style={{
                  transform: "scale(2)",
                  color: isOpen
                    ? "#61c0cb"
                    : onTop && logoAnimation
                      ? "#fac317"
                      : "#61c0cb",
                  transformOrigin: "top left",
                  background: "white",
                }}
                animate={{ scale: isOpen ? 1 : logoAnimation && onTop ? 2 : 1 }}

              // bg={!isOpen && "white"}
              // maxW={{ base: "180px", xl: onTop ? "300px" : "180px" }}
              // display={{ base: "none", xl: logoAnimation ? "block" : "none" }}
              >
                <Link to="/">
                  <LogoSVG />
                </Link>
              </motion.div>
            </Box>
            <PseudoBox display={{ xl: "none" }} color="secondary">
              <Link to="/">
                <LogoSVG />
              </Link>
            </PseudoBox>
          </Box>
          <Box display={{ base: "block", xl: "none" }}>
            <MenuToggle
              isOpen={isOpen}
              strokeWidth="4"
              color="#61c0cb"
              lineProps={{ strokeLinecap: "round" }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
              width="40"
              height="20"
              layoutId="button"
              onClick={() => toggleOpen()}
            />
          </Box>

          <Box
            display={{ base: "none", xl: "flex" }}
            w={{ base: "100%", xl: "auto" }}
          >
            <NavigationItem to="/">Startseite</NavigationItem>
            <NavigationItem to="/angebot">Unser Angebot</NavigationItem>
            <NavigationItem to="/blog">Aktuelles</NavigationItem>
            <NavigationItem to="/partner">Netzwerkpartner</NavigationItem>
          </Box>
        </Flex>
      </Box>
      <ButtonModal isOpen={isOpen} toggle={toggleOpen} />
    </Box>
  )
}

export default Navigation
