import React from "react"
import { PseudoBox } from "@chakra-ui/core"

const Button = ({ as = "button", children, ...props }) => {
  return (
    <PseudoBox
      as={as}
      display="inline-block"
      lineHeight="1.2"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      py="4"
      px="6"
      fontSize={{ base: "xl", lg: "2xl" }}
      fontWeight="extrabold"
      bg="primary"
      color="black"
      _hover={{ bg: "#ebedf0" }}
      _active={{
        bg: "#dddfe2",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
      {...props}
    >
      {children}
    </PseudoBox>
  )
}

export default Button
