import React from "react"
import { Box, PseudoBox, Text } from "@chakra-ui/core"
import { Col, Container, Row } from "react-grid-system"

const Intro = () => {
  return (
    <Box as={Container} pt="24">
      <Row>
        <Col component={Box} md={7} push={{ md: 4 }} display="flex">
          <PseudoBox
            pos={{ md: "absolute" }}
            top="0"
            left={{ md: "-10%" }}
            height="100%"
            width="24px"
            bg="secondary"
            mr={{ base: "6", md: 0 }}
            display="inline-block"
            flex="1 0 4%"
          />
          <PseudoBox
            pos={{ md: "absolute" }}
            top="0"
            left={{ md: "-18%" }}
            height="100%"
            width="24px"
            bg="secondary"
            display="inline-block"
            flex="1 0 4%"
          />
          <Text
            fontSize={{ base: "xl", md: "2xl", xl: "3xl" }}
            display="inline-block"
            ml={{ base: "6", md: "0" }}
          >
            MOIIN steht für die fünf Aspekte, die jedes Unternehmen, jede
            Organisation und jeder Einzelne im Blick haben muss, um mit den
            Herausforderungen der sich ständig verändernden Rahmenbedingungen
            wirtschaftlichen Handelns klar zu kommen.
          </Text>
        </Col>
      </Row>
    </Box>
  )
}

export default Intro
