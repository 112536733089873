import React from "react"
import Img from "gatsby-image"
import {
  AspectRatioBox,
  Heading,
  Image,
  Box,
  Text,
  PseudoBox,
} from "@chakra-ui/core"
import { motion } from "framer-motion"
import { Col, Container, Row } from "react-grid-system"

const Hero = ({ text, fluid, image, alt = "Hero Bild", small, primary }) => {
  const boxColor = primary ? "#FAC317" : "#61c0cb"
  const MotionBox = motion.custom(Box)
  const MotionPseudoBox = motion.custom(PseudoBox)

  return (
    <Box as={Container} pos="relative" pb={{ lg: "24" }}>
      <Box as={Row}>
        <MotionBox
          pos="absolute"
          top={{ base: "50%", md: "20%", xl: "40%" }}
          zIndex="1"
          w="100%"
          initial={{ x: "-30%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", duration: 0.6 }}
        >
          <Col md={8}>
            <Heading
              fontSize={{ base: "3xl", lg: "6xl", xl: "6xl" }}
              bg={boxColor}
              color="white"
              display="inline"
              lineHeight={{ base: "1.6", lg: "1.5" }}
            >
              <Text
                as="span"
                boxShadow={`-10px 0px 0 7px ${boxColor}, 10px 0px 0 7px ${boxColor}, 0 0 0 7px ${boxColor}`}
                style={{
                  boxDecorationBreak: "clone",
                }}
              >
                {text}
              </Text>
            </Heading>
          </Col>
        </MotionBox>
        <Col md={8} push={{ md: 4 }}>
          {small && (
            <Box
              pos="absolute"
              top={{ base: "70%", xl: "100%" }}
              left={{ md: "-18%", xl: "-14%" }}
              height={{
                base: "150px",
                md: "300px",
                xl: "200px",
              }}
              display={{ base: "none", lg: "block" }}
            >
              <PseudoBox
                width="24px"
                mr="10"
                height="100%"
                background={boxColor}
                display="inline-block"
              />
              <PseudoBox
                width="24px"
                height="100%"
                background={boxColor}
                display="inline-block"
              />
            </Box>
          )}

          <MotionPseudoBox
            pos="absolute"
            bottom="-10%"
            right="-30px"
            size="97px"
            border="10px solid"
            borderColor={boxColor}
            animate={{
              scale: [1, 1.5, 1.5, 1, 1],
              rotate: [0, 0, 180, 180, 0],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              loop: Infinity,
              repeatDelay: 2,
              delay: 1,
            }}
          />
          {!small ? (
            <Img fluid={image} />
          ) : (
            <>
              {fluid ? (
                <>
                  <Box display={{ base: "none", lg: "block" }}>
                    <Img fluid={{ ...image, aspectRatio: 2 / 1 }} />
                  </Box>
                  <Box display={{ base: "block", lg: "none" }}>
                    <Img fluid={{ ...image, aspectRatio: 1 / 1 }} />
                  </Box>
                </>
              ) : (
                <>
                  <AspectRatioBox
                    maxW="1088px"
                    ratio={2 / 1}
                    display={{ base: "none", lg: "block" }}
                  >
                    <Image src={image} objectFit="cover" alt={alt}></Image>
                  </AspectRatioBox>
                  <AspectRatioBox
                    maxW="1088px"
                    ratio={1 / 1}
                    display={{ base: "block", lg: "none" }}
                  >
                    <Image src={image} objectFit="cover" alt={alt}></Image>
                  </AspectRatioBox>
                </>
              )}
            </>
          )}
        </Col>
      </Box>
    </Box>
  )
}

export default Hero
