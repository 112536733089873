import React from "react"

import { BlockContent, BlockImage, Quote } from "./slices"

const SliceZone = ({ allSlices }) => {
  const slice = allSlices.map(slice => {
    switch (slice._type) {
      case "blockBody":
        return <BlockContent input={slice} key={slice._key} />
      case "blockImage":
        return <BlockImage input={slice} key={slice._key} />
      case "quote":
        return <Quote input={slice} key={slice._key} />
      default:
        return null
    }
  })
  return <>{slice}</>
}

export default SliceZone
