import { theme } from "@chakra-ui/core"

export default {
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#FAC317",
    secondary: "#61C0CB",
  },
  fonts: {
    ...theme.fonts,
    body: "Noto Sans, Calibri, system-ui, sans-serif",
    heading: "Noto Sans, Calibri, system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    ...theme.fontSizes,
    "8xl": "80px",
    "10xl": "96px",
  },
}
