import React from "react"
import { Link } from "gatsby"
import { Box, Heading } from "@chakra-ui/core"
import { Col, Container, Row } from "react-grid-system"

import { Button } from "."

const Quote = () => {
  return (
    <Box my={{ base: "20", lg: "32" }}>
      <Container>
        <Row>
          <Col md={8} push={{ md: 3 }}>
            <Box textAlign="right">
              <Heading fontSize={{ base: "3xl", md: "6xl", xl: "5xl" }} pb="4">
                MOIIN Veränderung. Was kommt, was bleibt, was wird.
              </Heading>
              <Button
                as={Link}
                to="/blog/unsere-einschaetzung-fuer-2020"
                fontSize={{ base: "18px", lg: "2xl" }}
              >
                Ausblick auf die Zukunft
              </Button>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  )
}

export default Quote
