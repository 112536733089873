import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ seo }) => {
  return (
    <>
      <Helmet title={`${seo ? seo.title : "Willkommen"} – MOIIN`}>
        <html lang="de" />
        <meta name="description" content={seo ? seo.description : "MOIIN"} />
        {/* <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="7a96caba-d58b-41f6-b9d3-83ce730ec858"
          data-blockingmode="auto"
          type="text/javascript"
        ></script> */}
      </Helmet>
    </>
  )
}
export default SEO
