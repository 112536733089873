import React from "react"
import { Box } from "@chakra-ui/core"
import "../css/global.css"

import { Footer, Navigation } from "."
import "../plugins/slick-slider/css/slick.min.css"
import "../plugins/slick-slider/css/slick-theme.min.css"

const Layout = ({ logoAnimation, children }) => {
  return (
    <>
      {/* <Example /> */}
      <Navigation logoAnimation={logoAnimation} />
      <Box as="main" mt="96px" overflow="hidden">
        {children}
      </Box>
      <Footer />
    </>
  )
}

export default Layout
