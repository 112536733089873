import React from "react"
import { Heading, Text, List, ListItem, ListIcon } from "@chakra-ui/core"
import { FaSquareFull } from "react-icons/fa"

export const ListRenderer = props => {
  return <List my="8">{props.children}</List>
}

export const ListItemRenderer = props => {
  return (
    <ListItem mb={{ base: "4", lg: 0 }} fontSize={{ base: "lg", lg: "xl" }}>
      <ListIcon
        fontSize="0.8rem"
        mr="6"
        icon={FaSquareFull}
        color="secondary"
      />
      {props.children}
    </ListItem>
  )
}
export const BlockRenderer = props => {
  const style = props.node.style || "normal"

  switch (style) {
    case "h1":
      return (
        <Heading as="h1" fontWeight="black" wordBreak="break-all">
          {props.children}
        </Heading>
      )
    case "h2":
      return (
        <Heading
          as="h2"
          fontWeight="bold"
          fontSize={{ base: "3xl", lg: "5xl" }}
          wordBreak="break-all"
          pb={{ base: 8, xl: 10 }}
        >
          {props.children}
        </Heading>
      )
    case "h3":
      return (
        <Heading
          as="h3"
          fontWeight="bold"
          fontSize={{ base: "2xl", lg: "4xl" }}
          pb={{ base: 8, xl: 10 }}
        >
          {props.children}
        </Heading>
      )
    case "h4":
      return (
        <Heading
          as="h4"
          fontWeight="bold"
          fontSize={{ base: "xl", xl: "3xl" }}
          pb="4"
        >
          {props.children}
        </Heading>
      )
    case "h5":
      return (
        <Heading as="h5" fontWeight="bold" fontSize={{ base: "lg", xl: "2xl" }}>
          {props.children}
        </Heading>
      )
    case "h6":
      return (
        <Heading as="h6" fontSize={{ base: "md", xl: "xl" }}>
          {props.children}
        </Heading>
      )
    case "normal":
      return (
        <Text
          fontSize={{ base: "lg", lg: "xl" }}
          style={{ "&:not(:last-of-type)": { margrinBottom: "32px" } }}
        >
          {props.children}
        </Text>
      )
    default:
      return null
  }
}
