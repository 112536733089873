import * as React from "react"
import { useRef } from "react"
import { motion } from "framer-motion"
import { Navigation } from "./Navigation"

import "./style.css"

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 90% 50px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0px at 90% 50px)",
    transition: {
      delay: 0.4,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
}

const navbar = {
  open: {
    height: "100vh",
  },

  closed: {
    height: "0",
    transition: {
      delay: 0.8,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
}

const ButtonModal = ({ isOpen, toggle }) => {
  const containerRef = useRef(null)

  return (
    <>
      <motion.div
        className="nav"
        initial={false}
        animate={isOpen ? "open" : "closed"}
        ref={containerRef}
        variants={navbar}
      >
        <motion.div className="background" variants={sidebar} />

        <Navigation onClick={() => toggle()} />
      </motion.div>
    </>
  )
}

export default ButtonModal
