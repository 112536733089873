import React from "react"
import { Box } from "@chakra-ui/core"
import Img from "gatsby-image"
import { Col, Container, Row } from "react-grid-system"
import Block from "@sanity/block-content-to-react"

import { BlockRenderer } from "../../../utils/htmlSerializer"

const BlockImage = ({ input }) => {
  return (
    <Box my={{ base: "20", lg: "32" }}>
      <Container>
        <Row
          component={Box}
          flexDirection={input.direction === "imageRight" && "row-reverse"}
        >
          <Col sm={12} lg={6}>
            <Img fluid={input.image.asset.fluid} />
          </Col>
          <Col sm={12} lg={6}>
            <Box mt={{ base: 6, lg: 0 }}>
              <Block
                blocks={input._rawText}
                serializers={{ types: { block: BlockRenderer } }}
              />
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  )
}

export default BlockImage
