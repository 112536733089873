import React from "react"
import {
  AspectRatioBox,
  Box,
  Flex,
  Heading,
  Image,
  PseudoBox,
  Text,
} from "@chakra-ui/core"
import { motion } from "framer-motion"
import { Col, Container, Row } from "react-grid-system"

const Person = ({ input }) => {
  const MotionPseudoBox = motion.custom(PseudoBox)

  return (
    <Box my={{ base: "20", lg: "32" }}>
      <Container>
        <Row
          component={Box}
          alignItems={{ base: "normal", xl: "center" }}
          flexDirection={input.reverse ? "row-reverse" : "row"}
          overflow={{ base: "hidden", lg: "visible" }}
        >
          <Col component={Box} xs={12} lg={5}>
            <Image src={input.image.publicURL} alt={input.alt} />
            <PseudoBox
              pos="absolute"
              size="100%"
              left={input.reverse ? "10%" : "-10%"}
              top="10%"
              zIndex="hide"
              display="inline-block"
            >
              <AspectRatioBox ratio={1 / 1}>
                <MotionPseudoBox
                  border="10px solid"
                  size="100%"
                  borderColor="secondary"
                  animate={{
                    x: [
                      0,
                      !input.reverse ? 70 : -70,
                      !input.reverse ? 70 : -70,
                      0,
                      0,
                    ],
                    y: [0, 70, 70, 0, 0],
                    scale: [1, 0.7, 0.7, 1, 1],
                  }}
                  transition={{
                    duration: 3,
                    ease: "easeInOut",
                    times: [0, 0.3, 0.6, 0.9, 1.1],
                    loop: Infinity,
                    repeatDelay: 2,
                  }}
                />
              </AspectRatioBox>
            </PseudoBox>
          </Col>
          <Col xs={12} lg={7} xxl={6}>
            <Box
              pl={{ lg: !input.reverse && "8" }}
              pr={{ lg: input.reverse && "8" }}
            >
              <Flex
                justify={{ lg: input.reverse && "flex-end" }}
                pb={8}
                pt={{ base: 8, lg: 0 }}
              >
                <PseudoBox
                  as="a"
                  href={input.social.linkedin.link}
                  rel="noopener noreferrer"
                  target="_blank"
                  mr="6"
                >
                  <Image
                    maxW={{ base: "50px", lg: "64px" }}
                    src={input.social.linkedin.icon.publicURL}
                  />
                </PseudoBox>
                <PseudoBox
                  as="a"
                  href={input.social.xing.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    maxW={{ base: "50px", lg: "64px" }}
                    src={input.social.xing.icon.publicURL}
                  />
                </PseudoBox>
              </Flex>

              <Box textAlign={{ lg: input.reverse && "right" }}>
                <Heading fontSize={{ base: "2xl", lg: "5xl" }} pb={8}>
                  {input.headline}
                </Heading>
                <Heading fontSize={{ base: "lg", lg: "xl" }} pb={8}>
                  {input.subline}
                </Heading>
                <Text
                  dangerouslySetInnerHTML={{ __html: input.text }}
                  fontSize={{ base: "lg", lg: "xl" }}
                />
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  )
}

export default Person
