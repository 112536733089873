import React, { useEffect, useState, useRef } from "react"
import { Box, Icon, PseudoBox } from "@chakra-ui/core"
import { Container } from "react-grid-system"
import Slider from "react-slick"

const ControlButton = props => {
  const { onClick, next } = props
  return (
    <PseudoBox
      onClick={onClick}
      pos="absolute"
      top="50%"
      transform="translateY(-50%)"
      cursor="pointer"
      right={next && "-40px"}
      left={!next && "-40px"}
      {...props}
    >
      <Icon
        name={next ? "chevron-right" : "chevron-left"}
        color="white"
        size="8rem"
      />
    </PseudoBox>
  )
}

const Carousel = ({ children }) => {
  const [nav, setNav] = useState()
  const slider = useRef(null)

  useEffect(() => {
    setNav(slider.current)
  }, [])

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoPlay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoPlay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          centerPadding: "80px",
          autoPlay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  }
  return (
    <Box
      bg="primary"
      py={{ base: "10", lg: "20" }}
      mb={{ base: "20", lg: "32" }}
    >
      <Container>
        <Box mx="-15px">
          <Slider ref={slider} {...settings}>
            {children}
          </Slider>
          {/* <ControlButton onClick={() => nav.slickPrev()} /> */}
          {/*<ControlButton onClick={() => nav.slickNext()} next />*/}
        </Box>
      </Container>
    </Box>
  )
}

export default Carousel
