import React from "react"
import { Link } from "gatsby"
import { Box, Link as ThemeLink, Text, PseudoBox } from "@chakra-ui/core"
import { motion } from "framer-motion"
import { Col, Container, Row } from "react-grid-system"

const FooterItem = ({ to, children, ...props }) => {
  return (
    <PseudoBox
      _hover={{ color: "secondary" }}
      display="inline-block"
      cursor="pointer"
      {...props}
    >
      <Link to={to} activeStyle={{ color: "#61C0CB" }}>
        {children}
      </Link>
    </PseudoBox>
  )
}

const Footer = () => {
  const MotionPseudoBox = motion.custom(PseudoBox)

  return (
    <Box as="footer" mt={{ base: "10", lg: 0 }}>
      <Box as={Container} pos="relative">
        <MotionPseudoBox
          pos="absolute"
          top="-30%"
          left={{ lg: "15%" }}
          size="97px"
          border="10px solid"
          borderColor="secondary"
          display="block"
          right={{ base: "4", lg: "auto" }}
          animate={{
            scale: [1, 0.7, 0.7, 1, 1],
            rotate: [0, 0, -180, -180, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            loop: Infinity,
            repeatDelay: 2,
          }}
        />
        <Row component={Box} py={{ base: "10", lg: "20" }}>
          <Col sm={12} md={4} push={{ md: 2 }}>
            <Box
              textAlign={{ base: "center", md: "left" }}
              mb={{ base: 8, md: 0 }}
            >
              <Text fontSize="3xl" fontWeight="bold">
                MOIIN GmbH
              </Text>
              <Text fontSize={{ base: "20px", lg: "xl" }}>
                Willy-Brandt-Straße 75
                <br />
                20459 Hamburg
                <br />
                <ThemeLink href="mailto:info@moiin.eu" color="primary">
                  info@moiin.eu
                </ThemeLink>
              </Text>
            </Box>
          </Col>
          <Col
            component={Box}
            sm={12}
            md={8}
            display="flex"
            alignItems="center"
          >
            <Box
              textAlign={{ base: "center", md: "right" }}
              w="100%"
              fontSize={{ lg: "xl" }}
            >
              <FooterItem to="/impressum">Impressum</FooterItem>
              <FooterItem ml="10" to="/datenschutz">
                Datenschutz
              </FooterItem>
            </Box>
          </Col>
        </Row>
      </Box>
    </Box>
  )
}

export default Footer
