const React = require("react")
const Layout = require("./src/components/Layout").default

exports.wrapPageElement = ({ element, location, props }) => {
  return (
    <Layout
      logoAnimation={element.props.path === "/" ? true : false}
      {...props}
    >
      {element}
    </Layout>
  )
}
