import React from "react"
import { Box } from "@chakra-ui/core"
import { Col, Container, Row } from "react-grid-system"
import Block from "@sanity/block-content-to-react"

import { BlockRenderer } from "../../../utils/htmlSerializer"

const Quote = ({ input }) => {
  const direction =
    input.direction === "right" ? 8 : input.direction === "left" ? 9 : 12
  const columnPush = input.direction === "right" ? 4 : 0
  return (
    <Box my={{ base: "20", lg: "32" }}>
      <Container>
        <Row>
          <Col md={direction} push={{ md: columnPush }}>
            <Box
              border={{ base: "15px solid", md: "20px solid" }}
              borderColor={{ base: "primary", md: "primary" }}
              px={{ base: "8", md: "16" }}
              py={{ base: "8", md: "10" }}
            >
              <Block
                blocks={input._rawBlock}
                serializers={{ types: { block: BlockRenderer } }}
              />
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  )
}

export default Quote
