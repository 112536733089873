import React from "react"
import { Box, Heading, Image, Text } from "@chakra-ui/core"
import { Col, Row } from "react-grid-system"

const IconText = ({ input }) => {
  return (
    <Box as={Row} mt="20" textAlign={{ base: "center", md: "left" }}>
      <Col md={3} align="center">
        <Image size="100px" src={input.icon.publicURL} alt={input.alt} />
      </Col>
      <Col md={8}>
        <Heading
          as="h3"
          fontSize={{ base: "2xl", lg: "4xl" }}
          mb="6"
          mt={{ base: "4", md: "0" }}
          style={{ hyphens: "auto" }}
        >
          {input.headline}
        </Heading>
        <Text fontSize={{ base: "md", lg: "xl" }}>{input.text}</Text>
      </Col>
    </Box>
  )
}

export default IconText
